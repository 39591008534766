import React from "react";

export default class Index extends React.Component {
    render() {
    const locale = this.props.locale
    return (
      <div className="container">
        <div className="content">
          <h1>{ locale === "en" ? ("Contact Form") : ("İletişim Formu") }</h1>
        <form name="contact-form" method="post" data-netlify="true" data-netlify-honeypot="bot-field" action="/contact/thanks/">
          <input type="hidden" name="form-name" value="contact" />
          <div hidden>
            <label>
              Don’t fill this out:{" "}
              <input name="bot-field" onChange={this.handleChange} />
            </label>
          </div>
          <div className="field">
            <label htmlFor="contact-name" className="label">
              {locale === "en" ? ("Your name") : ("Adınız")}
              <input name="name" className="input" id="contact-name" type="text" required />
            </label>
          </div>
          <div className="field">
            <label htmlFor="contact-email" className="label">
              {locale === "en" ? ("Email") : ("Email adresiniz")} <input name="email" className="input" id="contact-email" type="email" required />
            </label>
          </div>
          <div className="field">
            <label htmlFor="contact-message" className="label">
              {locale === "en" ? ("Your Message") : ("Mesajınız")} <textarea name="message" className="textarea" id="contact-message" required />
            </label>
          </div>
          <div className="field">
            <button className="button is-link">{locale === "en" ? ("Send") : ("Gönder")}</button>
          </div>
          <input type="hidden" name="form-name" value="contact-form" />
        </form>
        </div>
      </div>
    );
  }
}
