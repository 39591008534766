import React from 'react'
import PropTypes from 'prop-types'
import Helmet from "react-helmet";
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import ContactForm from "../components/ContactForm";

export const ContactPageTemplate = ({ title, content, contentComponent, locale }) => {
  const PageContent = contentComponent || Content

  return (
    <div>
      <section className="section section--gradient">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <h2 className="title is-size-3">{title}</h2>
              <PageContent className="content" content={content} />
            </div>
          </div>
        </div>
      </section>
      <div className="contact-form">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <ContactForm locale={locale} />
            </div>              
          </div>
        </div>
      </div>
    </div>
  );
}

ContactPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  locale: PropTypes.string,
  contentComponent: PropTypes.func,
}

const ContactPage = ({ pageContext: { locale }, ...props }) => {
  const { node: data } = props.data.ContactPageData.edges[0]

  return (
    <Layout locale={locale}>
      <Helmet>
        <title>{data.frontmatter.title}</title>
        <meta
          name="description"
          content={`${data.frontmatter.description}`}
        />
      </Helmet>
      <ContactPageTemplate
        contentComponent={HTMLContent}
        title={data.frontmatter.title}
        content={data.html}
        locale={data.frontmatter.locale}
      />
    </Layout>
  );
}

ContactPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};


export default ContactPage

export const contactPageQuery = graphql`
query ContactPage($locale: String) {
  ContactPageData: allMarkdownRemark(
    filter: {frontmatter: { templateKey: {eq: "contact-page"}, locale: { eq: $locale }}}
  ) {
    edges {
      node {
        fields {
          slug
        }
        html
        frontmatter {
          title
          locale
        }
      }
    }
  }
}
`
